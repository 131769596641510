<template>
  <WeContainer card="" v-if="ready">
    <div class="row">
      <div class="col-12 col-lg-2">
        <Navigation />
      </div>
      <div class="col">
        <Content v-bind:settings="session.config" v-on:on-save="onSave" />
      </div>
    </div>
  </WeContainer>
  <WeLoading v-else />
</template>
<script>
import Navigation from "./views/Navigation/Index";
import Content from "./views/Content/Index";

import { mapActions, mapMutations, mapState } from "vuex";
export default {
  name: "Detail",
  data() {
    return {
      ready: false,
    };
  },
  components: {
    Navigation,
    Content,
  },
  methods: {
    ...mapActions("session", ["getConfig", "updateConfig"]),
    ...mapActions("shared", [
      "getCurrencyList",
      "getPaymentTypes",
      "getQuantityTypes",
      "getDiscountTypes",
      "getPromotionTypes",
    ]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    onSave(data) {
      this.session.config = data;
      this.updateConfig({
        onSuccess: (result) => {
          this.showAlert(result);
        },
      });
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    loadMounted() {
      this.getConfig();
      this.getPaymentTypes();
      this.getQuantityTypes();
      this.getDiscountTypes();
      // this.getPromotionTypes();
      this.getCurrencyList({
        onFinish: () => (this.ready = true),
      });
    },
  },
  computed: {
    ...mapState(["session", "shared"]),
  },
  mounted() {
    this.loadMounted();
  },
};
</script>
