<template>
  <div class="tab-content">
    <!-- <Application v-bind:settings="settings" /> -->
    <Site v-bind:settings="settings" />
    <General v-bind:settings="settings" />
    <Mail v-bind:settings="settings" />
    <Analyze v-bind:settings="settings" />
    <Order v-bind:settings="settings" />
    <Cargo v-bind:settings="settings" />
    <Member v-bind:settings="settings" />
    <Cart v-bind:settings="settings" />
    <Product v-bind:settings="settings" />
    <CompetitorPriceAnalysis v-bind:settings="settings" />
    <Integrations v-bind:settings="settings" />
    <ViewSetting v-bind:settings="settings" />
  </div>
</template>
<script>
// import Application from "./views/Application/Index";
const Site = () => import("./views/Site/Index");
const General = () => import("./views/General/Index");
const Mail = () => import("./views/Mail/Index");
const Analyze = () => import("./views/Analyze/Index");
const Order = () => import("./views/Order/Index");
const Cargo = () => import("./views/Cargo/Index");
const Member = () => import("./views/Member/Index");
const Cart = () => import("./views/Cart/Index");
const Product = () => import("./views/Product/Index");
const CompetitorPriceAnalysis = () => import("./views/PriceAnalysis/Index");
const Integrations = () => import("./views/Integrations/Index");
const ViewSetting = () => import("./views/View/Index");

export default {
  name: "Content",
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    // Application,
    Site,
    General,
    Mail,
    Analyze,
    Order,
    Cargo,
    Member,
    Cart,
    Product,
    Integrations,
    ViewSetting,
    CompetitorPriceAnalysis,
  },
};
</script>
