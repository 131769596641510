<template>
  <div class="card mb-3 shadow-sm">
    <div class="card-body">
      <!-- Tab Navigation -->
      <ul class="nav nav-pills" id="pills-tab" role="tablist">
        <!-- Site Settings -->
        <li class="col-12 p-0 nav-item mb-2" role="presentation">
          <a
            href="#site-setting"
            class="nav-link active"
            id="site-setting-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="site-setting"
            aria-selected="true"
          >
            <span class="ml-1">Site Ayarları</span>
          </a>
        </li>
        <!-- ./Site Settings -->

        <!-- General Settings -->
        <li class="col-12 p-0 nav-item mb-2" role="presentation">
          <a
            href="#general-setting"
            class="nav-link"
            id="general-setting-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="general-setting"
            aria-selected="true"
          >
            <span class="ml-1">Genel Ayarlar</span>
          </a>
        </li>
        <!-- ./General Settings -->

        <!-- Mail Settings -->
        <li class="col-12 p-0 nav-item mb-2" role="presentation">
          <a
            href="#mail-setting"
            class="nav-link"
            id="mail-setting-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="mail-setting"
            aria-selected="true"
          >
            <span class="ml-1">Mail Ayarları</span>
          </a>
        </li>
        <!-- ./Mail Settings -->

        <!-- View Settings -->
        <li class="col-12 p-0 nav-item mb-2" role="presentation">
          <a
            href="#view-setting"
            class="nav-link"
            id="view-setting-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="view-setting"
            aria-selected="true"
          >
            <span class="ml-1">Görsel Ayarlar</span>
          </a>
        </li>
        <!-- ./View Settings -->

        <!-- Integrations -->
        <li class="col-12 p-0 nav-item mb-2" role="presentation">
          <a
            href="#integration-setting"
            class="nav-link"
            id="integration-setting-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="integration-setting"
            aria-selected="true"
          >
            <span class="ml-1">Entegrasyon</span>
          </a>
        </li>
        <!-- ./Integrations -->

        <!-- Application Settings -->
        <!-- <li class="col-12 p-0 nav-item mb-2" role="presentation">
          <a
            href="#app-setting"
            class="nav-link"
            id="app-setting-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="app-setting"
            aria-selected="false"
          >
            <span class="ml-1">CDN</span>
          </a>
        </li> -->
        <!-- ./Application Settings -->

        <!-- Internet App Settings -->
        <li class="col-12 p-0 nav-item mb-2" role="presentation">
          <a
            href="#int-setting"
            class="nav-link"
            id="int-setting-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="int-setting"
            aria-selected="false"
          >
            <span class="ml-1">Analiz & Takip</span>
          </a>
        </li>
        <!-- ./Internet App Settings -->

        <!-- Order Settings -->
        <li class="col-12 p-0 nav-item mb-2" role="presentation">
          <a
            href="#order-setting"
            class="nav-link"
            id="order-setting-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="order-setting"
            aria-selected="false"
          >
            <span class="ml-1">Sipariş</span>
          </a>
        </li>
        <!-- ./Order Settings -->

        <!-- Cargo Settings -->
        <li class="col-12 p-0 nav-item mb-2" role="presentation">
          <a
            href="#cargo-setting"
            class="nav-link"
            id="cargo-setting-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="cargo-setting"
            aria-selected="false"
          >
            <span class="ml-1">Kargo</span>
          </a>
        </li>
        <!-- ./Cargo Settings -->

        <!-- Member Settings -->
        <li class="col-12 p-0 nav-item mb-2" role="presentation">
          <a
            href="#member-setting"
            class="nav-link"
            id="member-setting-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="member-setting"
            aria-selected="false"
          >
            <span class="ml-1">Üye</span>
          </a>
        </li>
        <!-- ./Member Settings -->

        <!-- Cart Settings -->
        <li class="col-12 p-0 nav-item mb-2" role="presentation">
          <a
            href="#cart-setting"
            class="nav-link"
            id="cart-setting-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="cart-setting"
            aria-selected="false"
          >
            <span class="ml-1">Sepet</span>
          </a>
        </li>
        <!-- ./Cart Settings -->

        <!-- Product Settings -->
        <li class="col-12 p-0 nav-item mb-2" role="presentation">
          <a
            href="#product-setting"
            class="nav-link"
            id="product-setting-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="product-setting"
            aria-selected="false"
          >
            <span class="ml-1">Ürün</span>
          </a>
        </li>
        <!-- ./Product Settings -->

        <!-- CompetitorPriceAnalysis Settings -->
        <li class="col-12 p-0 nav-item mb-2" role="presentation">
          <a
            href="#competitor-price-analysis-setting"
            class="nav-link"
            id="competitor-price-analysis-setting-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="competitor-price-analysis-setting"
            aria-selected="false"
          >
            <span class="ml-1">Fiyat Analizi</span>
          </a>
        </li>
        <!-- ./CompetitorPriceAnalysis Settings -->
      </ul>
      <!-- Tab Navigation -->
    </div>
  </div>
</template>
<script>
export default {
  name: "Navigation",
};
</script>
